import React, { Component } from 'react';
import { Col, Form } from 'react-bootstrap';
import { IoIosClock } from 'react-icons/io';
import Clock from 'react-live-clock';
import './Footer.css';

export class Footer extends Component {
    displayName = Footer.name;
    
      constructor(props) {
          super(props);
          this.state = {
              isOnline: navigator.onLine,
          };
      }

      componentDidMount() {
          this.checkConnection();
          this.interval = setInterval(this.checkConnection, 30000); // Check every 30 seconds
          window.addEventListener('online', this.updateOnlineStatus);
          window.addEventListener('offline', this.updateOnlineStatus);
      }

      componentWillUnmount() {
          clearInterval(this.interval);
          window.removeEventListener('online', this.updateOnlineStatus);
          window.removeEventListener('offline', this.updateOnlineStatus);
      }

      updateOnlineStatus = () => {
          this.setState({ isOnline: navigator.onLine });
      };

      checkConnection = () => {
        fetch('api/HealthCheckController/isConnected', { method: 'GET', cache: 'no-store' })
            .then(response => {
                if (response.ok) {
                    this.setState({ isOnline: true });
                } else {
                    this.setState({ isOnline: false });
                }
            })
            .catch(error => {
                this.setState({ isOnline: false });
            });
    };

    render() {

      const { isOnline } = this.state;
      return (
        <footer className='wodinFooter'>
          <Form>
            <Form.Row>
              <Col md={2}>
                <span>&copy; {(new Date().getFullYear())} Wood</span>
              </Col>
              <Col md={8}>
                <span>{isOnline ? 'Online' : 'Offline'}</span>
              </Col>
              <Col md={2}>
                <IoIosClock /> <Clock format={'MMM DD, YYYY HH:mm:ss (UTC)'} ticking={true} timezone={'UTC'} />
              </Col>
            </Form.Row>
          </Form>
                
        </footer>
      );
    }
}
